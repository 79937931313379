import React from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/colors";
import SilkCabsFooter from "../../components/SilkCabsFooter";

const ContactUs = () => {
  return (
    <div>
      <PageContainer>
        <SectionTitle>Contact Us</SectionTitle>
        <MainContainer>
          <PageContent>
            <Description>
              <ContactItem>
                <h3>General Inquiries.</h3>
                <p>
                  For general inquiries and information about GoSawari, please
                  contact us at:
                  <br />
                  <span>Email:</span> support@gosawari.com
                </p>
              </ContactItem>
              <ContactItem>
                <h3>Customer Support.</h3>
                <p>
                  Our dedicated customer support team is available to assist
                  you. If you have any questions, concerns, or need assistance,
                  please reach out to:
                  <br />
                  <span>Email:</span> support@gosawari.com
                  <br />
                  <span>Phone:</span> +92-345-706-8896
                  <br />
                  <span>Address:</span> House of Tayyaba Asghar,opposite Kala
                  Shop, qadriabad, Jhelum.
                  <br />
                </p>
              </ContactItem>

              <ContactItem>
                <h3>Social Media.</h3>
                <p>
                  Connect with us on social media for updates and news:
                  <br />
                  <strong>Facebook:</strong>{" "}
                  <a href="https://www.facebook.com/gosawari/" target="blank">
                    GoSawari.com
                  </a>
                  <br />
                  {/* <strong> Twitter: </strong>abc @GoSawari
                <br />
                <strong>Instagram: </strong> abc @GoSawari.
                <br />
                <strong>LinkedIn: </strong> abc @GoSawari.
                <br /> */}
                  We value your feedback and look forward to assisting you!
                </p>
              </ContactItem>
            </Description>
          </PageContent>
        </MainContainer>
      </PageContainer>
      <SilkCabsFooter />
    </div>
  );
};

export default ContactUs;

const PageContainer = styled.div`
  background-color: ${COLORS.lightBackgroundColor};
  height: auto;
  padding: 3rem;
  @media (max-width: 546px) {
    padding: 1rem;
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin: 3.75rem 0;
  font-size: 3.4375rem;
  font-weight: 100;
`;

const MainContainer = styled.div`
  background-color: white;
  max-width: 1000px;
  min-height: 900px;
  margin: auto;
  padding: 5rem;
  @media (max-width: 546px) {
    padding: 1rem;
  }
`;

const PageContent = styled.div`
  width: 100%;
  height: auto;
`;

const Description = styled.div`
  height: auto;
  width: 100%;
  margin-top: 4rem;
  @media (max-width: 546px) {
    margin-top: 1rem;
  }
`;

const ContactItem = styled.div`
  margin-bottom: 2rem;

  h3 {
    font-size: 28px;
    font-weight: 100;
    margin-bottom: 1.5rem;
    color: #5d5d62;
    @media (max-width: 546px) {
      font-size: 1.4rem;
    }
  }

  p {
    color: ${COLORS.secondaryTextColor};
    line-height: 1.4;
    display: inline-block;
  }

  span {
    font-weight: bold;
    margin-right: 0.2rem;
  }
`;
