export default {
  api: {
    url: "https://walrus-app-s22bb.ondigitalocean.app/api/v1/",
    baseUrl: "https://walrus-app-s22bb.ondigitalocean.app",
  },
  featureFlags: {
    freeLaunch: true,
  },
  recaptchaKey: process.env.REACT_APP_RECAPTCHA,
  fbAppId: process.env.REACT_APP_FB_APP_ID,
  gtmId: process.env.REACT_APP_GTM_ID_DEV,
  env: "production",
  log: "debug",
};
